import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import Main from '../../components/Main';
import { Form, Formik } from 'formik';
import Card from '../../components/Card';
import Aside from '../../components/Aside';
import Icon from '../../components/Icon';
import StepTitle from '../../components/StepTitle';
import Alert from '../../components/Alert';
import Radio from '../../components/fields/Radio';
import Container from '../../components/Container';
import StepNavigation from '../../components/StepNavigation';
import { getResponseErrors, jsonApiAxios } from '../../services/api';
import { steps } from '../../utils/routes';
import infoIcon from '../../assets/icons/info-icon-grey.svg';
import { useCoBrandingParams } from '../../hooks/useCobrandingParams';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { Redirect } from '@reach/router';

const unsubscribeOptions = [
  'already_collected',
  'another_moment',
  'another_company',
  'no_interest',
  'not_missed',
].map(value => ({
  // @ts-ignore
  label: messages[value],
  value,
}));

const Unsubscribe = () => {
  const { formatMessage } = useIntl();

  const [cobrandingDossierTokenId] = useLocalStorageState(
    'cobrandingDossierTokenId'
  );

  const { dynamicConfig } = useCoBrandingParams();

  const [, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [success, setSuccess] = useState(false);

  function handleSubmit(values, { setSubmitting }) {
    setErrors(null);
    setSuccess(false);
    setIsSubmitting(true);
    jsonApiAxios
      .patch(`/dossierTokens/${cobrandingDossierTokenId}`, values, {
        noSerialize: true,
      })
      .then(() => {
        setSuccess(true);
        setSubmitting(false);
        setIsSubmitting(false);
      })
      .catch(error => {
        setSuccess(false);
        setSubmitting(false);
        setIsSubmitting(false);
        if (error?.response) {
          setErrors(getResponseErrors(error));
        }
      });
  }

  if (cobrandingDossierTokenId)
    return (
      <Container>
        <Main className="m-auto sm:max-w-740">
          <Formik initialValues={{}} onSubmit={handleSubmit}>
            {() => (
              <Form>
                <Card onlyDesktop={true}>
                  <StepTitle>
                    {formatMessage(messages.unsubscribeTitle)}
                  </StepTitle>
                  <Card onlyMobile={true}>
                    <p className="mb-40">
                      {formatMessage(messages.unsubscribeDescription)}
                    </p>
                    <Radio
                      className="sm:max-w-none max-w-none"
                      layout="vertical"
                      name="opt_out_reason"
                      options={unsubscribeOptions}
                    />
                  </Card>
                </Card>
                <StepNavigation
                  submitButtonMessage={formatMessage(
                    messages.unsubscribeButtonText
                  )}
                  step={steps.Unsubscribe}
                />
                {success ? (
                  <Alert level="success" className="w-full my-20">
                    {formatMessage(messages.unsubscribeDoneMessage)}
                  </Alert>
                ) : Array.isArray(errors) ? (
                  errors.map((error, index) => (
                    <Alert
                      // eslint-disable-next-line
                      key={`error-${index}`}
                      level="danger"
                      className="w-full my-20"
                    >
                      {error}
                    </Alert>
                  ))
                ) : null}
              </Form>
            )}
          </Formik>
        </Main>
        {dynamicConfig ? (
          <Aside className="sm:py-20">
            <Icon src={infoIcon} className="mb-20" />
            <h4>{dynamicConfig?.siteName}</h4>
            <ul className="mt-15">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={dynamicConfig?.howItWorksUrl}
                >
                  {formatMessage(messages.howDoesItWork)}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={dynamicConfig?.serviceUrl}
                >
                  {formatMessage(messages.service)}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={dynamicConfig?.faqUrl}
                >
                  {formatMessage(messages.frequentlyAskedQuestions)}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={dynamicConfig?.testimonialsUrl}
                >
                  {formatMessage(messages.customerExperiences)}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={dynamicConfig?.contactUrl}
                >
                  {formatMessage(messages.contact)}
                </a>
              </li>
            </ul>
          </Aside>
        ) : null}
      </Container>
    );

  return <Redirect noThrow={true} to={'/404'} />;
};

export default Unsubscribe;
