import React from 'react';

import PageTransition from '../components/PageTransition';
import routes from '../messages/routes';
import { steps } from '../utils/routes';
import Unsubscribe from '../views/Unsubscribe';

const UnsubscribePage = () => (
  <PageTransition showStepper={false} hideMenu>
    <Unsubscribe path={routes[steps.Unsubscribe]} />
  </PageTransition>
);

UnsubscribePage.propTypes = {};

export default UnsubscribePage;
