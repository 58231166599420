import { defineMessages } from 'react-intl';

export default defineMessages({
  unsubscribeTitle: {
    id: 'cc4.unsubscribe.page-title',
    defaultMessage: 'Unsubscribe from this flight',
  },
  unsubscribeDescription: {
    id: 'cc4.unsubscribe.description',
    defaultMessage:
      'In order to provide you with the best possible service, we would like to ask you to choose one of the options below so that we know if we can contact you again for this flight in the future.',
  },
  already_collected: {
    id: 'cc4.unsubscribe.radio.already_collected',
    defaultMessage: 'I have already received compensation for this flight',
  },
  another_moment: {
    id: 'cc4.unsubscribe.radio.another_moment',
    defaultMessage: 'I would like to watch this at another time',
  },
  another_company: {
    id: 'cc4.unsubscribe.radio.another_company',
    defaultMessage: 'I already have a claim through another company',
  },
  no_interest: {
    id: 'cc4.unsubscribe.radio.no_interest',
    defaultMessage: 'I am not interested in using this service',
  },
  not_missed: {
    id: 'cc4.unsubscribe.radio.not_missed',
    defaultMessage: 'I just made my flight',
  },
  unsubscribeButtonText: {
    id: 'cc4.unsubscribe.button-text',
    defaultMessage: 'Unsubscribe',
  },
  unsubscribeDoneMessage: {
    id: 'cc4.unsubscribe.done-message',
    defaultMessage: 'Thank you! You have been successfully unsubscribed',
  },
  howDoesItWork: {
    id: 'cc4.unsubscribe.aside-links.how-does-it-work',
    defaultMessage: 'How does it work',
  },
  service: {
    id: 'cc4.unsubscribe.aside-links.service',
    defaultMessage: 'Service',
  },
  frequentlyAskedQuestions: {
    id: 'cc4.unsubscribe.aside-links.frequently-asked-questions',
    defaultMessage: 'Frequently Asked Questions',
  },
  customerExperiences: {
    id: 'cc4.unsubscribe.aside-links.customer-experiences',
    defaultMessage: 'Customer Experiences',
  },
  contact: {
    id: 'cc4.unsubscribe.aside-links.contact',
    defaultMessage: 'Contact',
  },
});
